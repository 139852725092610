import { Injectable } from '@angular/core';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import {Observable, of} from 'rxjs';
import { Constants } from 'src/constants';
import { CoreUtil as c } from 'src/app/core/core-util';
import {PricingArchitecture, ResponsePricingArchitecture} from '../model/pricing-architecture.model';
import { Response } from 'src/app/resource/dto/response';
import {GpInformation, ResponseGpInformation} from '../model/gp-information.model';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

export class PricingArchitectureService extends AbstractService<any>  {
	public mainDataObservable: Observable<any>;

	constructor(httpClientService: HttpClientService) {
		super(httpClientService);
		this._microService = Constants.constant.microServices.commercial;
	}

	isValid(commercial: any): boolean {
		return c.isNotEmpty(commercial) && c.isNotEmpty(commercial);
	}

	getRequestData(commercial: any) {
		return {};
	}

	updateState(commercial: any) {
		this._trigger.next(commercial);
	}

	updateHistoryState(commercial: any) {
		this._historyTrigger.next(commercial);
	}

	getQuotationDetails(id: number): Observable<Response<ResponsePricingArchitecture>> {
		const endPoint = `architecture?quotationId=${id}`;
		return this.httpClientService.get(this._microService, endPoint, null);
	}

	getGpInformation(currency: string, quotationId: number): Observable<Response<ResponseGpInformation>> {
		return this.httpClientService.get(
			this._microService,
			`architecture/gp-information`,
			`currency=${currency}&quotationId=${quotationId}`
		);
	}

	updatedIsReady(idList: number[]): Observable<Response<boolean>> {
		const endPoint = `architecture/updated?quotationIds=${idList.join(',')}`;
		return this.httpClientService.get(this._microService, endPoint, null);
	}

	saveApprovalDecision(quotationDecisionData: any): Observable<any> {
		return this.httpClientService.post(this._microService, 'quotation/approval', quotationDecisionData);
	}

}